import {ConvertedModule, DataObject, Event, Mapping, Module, TransformedModel} from "types";

export function extractModuleNames(data: DataObject): string[] {
  const moduleNames: string[] = [];

  data.events.forEach((event: Event) => {
    event.modules.forEach((module: Module) => {
      moduleNames.push(module.name);
    });
  });

  return moduleNames;
}

export const transformObject = (inputObject: { events: Event[] }): TransformedModel => {
  const transformedModel: TransformedModel = {};
  let count = 0;

  inputObject.events.forEach((event: Event) => {
    event.modules.forEach((module: Module) => {
      // @ts-ignore
      const mappingObject: TransformedModelItem = {
        module: module.name,
        event: event.name,
        isExpanded: true,
      };

      module.mappings.forEach(({ foreign, local, paths }: Mapping) => {
        mappingObject[foreign] = local;
      });

      transformedModel[count++] = mappingObject;
    });
  });

  return transformedModel;
};

const ignoredApiKeys = {
  event: true,
  module: true,
  isExpanded: true,
};

export const convertToApiModel = (inputObject: Record<string, any>): {
  events: { name: string; modules: ConvertedModule[] }[]
} => {
  const moduleMap: Record<string, ConvertedModule[]> = {};

  Object.values(inputObject).forEach((obj: any) => {
    const eventName = obj.event;
    console.log(obj, 'objobjobjobjobj');
    
    const module: ConvertedModule = {
      // @ts-ignore
      mappings: Object.entries(obj).filter(([key, value]) => !ignoredApiKeys[key] && key && value).map(([foreign, local]: [string, any, string[]]) => ({
        local,
        foreign,
      })),
      name: obj.module,
    };

    if (!moduleMap[eventName]) {
      moduleMap[eventName] = [];
    }

    moduleMap[eventName].push(module);
  });

  const convertedModules: { name: string; modules: ConvertedModule[] }[] = Object.keys(moduleMap).map(eventName => ({
    name: eventName,
    modules: moduleMap[eventName],
  }));

  return { events: convertedModules };
};
