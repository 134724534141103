import { useState, useEffect } from 'react';

interface FetchState<T> {
  data: T | null;
  isLoading: boolean;
  error: string | null;
}

type HttpMethod = 'GET' | 'POST';

interface FetchOptions {
  method: HttpMethod;
  body?: string;
  headers?: HeadersInit;
}

const useFetch = <T>(
  url: string,
  options: FetchOptions = { method: 'GET' }
): FetchState<T> => {
  const [data, setData] = useState<T | null>(null);
  const [isLoading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);

        const response = await fetch(url, {
          method: options.method,
          body: options.body,
          credentials: 'include',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            ...options.headers,
          },
        });

        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const result = await response.json();
        setData(result);
      } catch (error: any) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [url, options.method, options.body, options.headers]);

  return { data, isLoading, error };
};

export default useFetch;
