interface IFetchResponse {
  headers: Headers;
  status: number;
  [key: string]: any;
}

class Fetch {
  static async get(url: string, signal?: AbortSignal | null): Promise<IFetchResponse> {
    return Fetch.request(url, 'GET', null, signal);
  }

  static async post(url: string, data: any, signal?: AbortSignal | null): Promise<IFetchResponse> {
    return Fetch.request(url, 'POST', data, signal);
  }

  static async put(url: string, data: any, signal?: AbortSignal | null): Promise<IFetchResponse> {
    return Fetch.request(url, 'PUT', data, signal);
  }

  static async request(
      url: string,
      method: string,
      data: any,
      signal?: AbortSignal | null
  ): Promise<IFetchResponse> {

    const headers: HeadersInit = {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
    };

    const body = (method === 'POST' || method === 'PUT') ? JSON.stringify(data) : undefined;

    const response = await fetch(url, { credentials: 'include', method, headers, body, signal });

    const result = await response.clone().json();

    return {
      headers: response.headers,
      status: response.status,
      ...result,
    };
  }
}

export default Fetch;
