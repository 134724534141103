import { useState, useCallback } from 'react';

import Fetch from 'utils/Fetch';

interface FetchOnceProps<T> {
  initialData: T;
  url: string;
  isGet?: boolean;
}

const useFetchOnce = <T>({
  initialData,
  url,
  isGet = true,
}: FetchOnceProps<T>): { isLoading: boolean; fetchData: (reqData: any) => Promise<T> | null; data: T } => {
  const [data, setData] = useState<T>(initialData);
  const [isLoading, setIsLoading] = useState(false);

  const fetchData = useCallback(
    async (reqData: any) => {
      if (!url) return initialData;
      setIsLoading(true);

      const data = !isGet
        ? await Fetch.post(url, reqData)
        : await Fetch.get(`${url}${reqData}`);

      let currentData = initialData;

      if (data) {
        currentData = data as T;
        setData(currentData);
      } else {
        currentData = initialData;
        setData(currentData);
      }

      setIsLoading(false);
      return currentData;
    },
    [url, isGet, initialData]
  );

  return { data, fetchData, isLoading };
};

export default useFetchOnce;
