import EventForm from "components/EventForm";

import useFetchData from "utils/hooks/useFetchData";

import './index.css';

const { REACT_APP_ZOHO_MODULES_URL } = process.env;

function Main() {
  const { data: mapsData } = useFetchData(`${REACT_APP_ZOHO_MODULES_URL}/zoho/mappings`);

  return (
    <div className="main-holder">
      <div className="main-header">
        <div className="main-title">
          <h1>ZOHO / Mapping</h1>
          <p>Select your events to map Zoho modules</p>
        </div>
      </div>
      <div className="main-content">
        <EventForm maps={mapsData} />
      </div>
    </div>
  );
}

export default Main;
