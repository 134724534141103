import { useState } from "react";

import { Button, Form, Select } from 'antd';

import './index.css';

function EventNestedForm({ handleChange, id, zohoFieldList, eventFieldsList, restField, fieldName, maps }: { handleChange: any, id: number, zohoFieldList: any, eventFieldsList?: any, restField: any, fieldName: number, maps: any }) {
  const [isFieldsShown, setIsFieldsShown] = useState(!maps[id]?.isExpanded);

  return (
    <>
      <div className={`event-nested-form ${isFieldsShown ? '' : 'closed'}`}>
        {zohoFieldList.map(({ label, required, name, type }: any, i: number) => (
          <Form.Item {...restField} key={i} label={label} className="nested-form-item" initialValue={maps[id]?.[name]} rules={[{ required: required, message: 'Required' }]} name={[fieldName, name]}>
            <Select
              allowClear
              size="large"
              bordered={false}
              placeholder="Select Module"
              onChange={value => handleChange(value, id, name)}
            >
              {eventFieldsList.filter((field: any) => field.type === type).map(({ label, name }: any, i: number ) => (
                <Select.Option key={`${i}${name}`} value={name}>
                  {label}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        ))}
      </div>
      <div className="collapse-button-holder">
        <Button
          onClick={() => setIsFieldsShown((prev: any) => !prev)}
        >
          {isFieldsShown ? 'Collapse' : 'Expand'}
        </Button>
      </div>
    </>
  );
}

export default EventNestedForm;
