import React, { useEffect, useState } from "react";
import { Form, Button, Space, Select, Modal } from "antd";

import useFetchData from "utils/hooks/useFetchData";
import useFetchOnce from "utils/hooks/useFetchOnce";
import EventNestedForm from "../EventNestedForm";

import { ReactComponent as PlusIcon } from "assets/icons/svg/plus.svg";
import { ReactComponent as CloseIcon } from "assets/icons/svg/close.svg";

import {
  convertToApiModel,
  extractModuleNames,
  transformObject
} from "utils/helpers";

import {
  EventFormProps,
  TransformedModel,
  ZohoModule
} from "types";

import './index.css';

const { REACT_APP_ZOHO_MODULES_URL } = process.env;

const EventForm: React.FC<EventFormProps> = ({ maps: initialMaps }) => {
  const [form] = Form.useForm();
  const [zohoFields, setZohoFields] = useState({});
  const [events, setEvents] = useState<any>([]);
  const [selectedModule, setSelectedModule] = useState<string>('');
  const [maps, setMaps] = useState<TransformedModel>({});
  const [zohoModules, setZohoModules] = useState<ZohoModule[]>([]);
  const [disconnectModalState, setDisconnectModalState] = useState(false);

  const {
    data: ZohoModules,
    isLoading: modulesLoading
  } = useFetchData(`${REACT_APP_ZOHO_MODULES_URL}/zoho/modules`);
  const {
    data: EventsWithFields,
    isLoading: eventsLoading
  } = useFetchData(`${REACT_APP_ZOHO_MODULES_URL}/zoho/events`);
  const {
    isLoading: postMappingsLoadingState,
    fetchData: postMappings
  } = useFetchOnce({ initialData: {}, url: `${REACT_APP_ZOHO_MODULES_URL}/zoho/mappings`, isGet: false });
  const {
    data: ZohoFields,
    isLoading: fieldLoading,
    fetchData
  } = useFetchOnce({ initialData: {}, url: `${REACT_APP_ZOHO_MODULES_URL}/zoho/fields?moduleName=` });
  const {
    data: disconnect,
    isLoading: disconnectLoading,
    fetchData: disconnectRequest
  } = useFetchOnce({ initialData: { success: false }, url: `${REACT_APP_ZOHO_MODULES_URL}/zoho/disconnect`, isGet: false });

  useEffect(() => {
    // @ts-ignore
    !eventsLoading && setEvents(EventsWithFields?.events);
  }, [eventsLoading]);

  useEffect(() => {
    // @ts-ignore
    !fieldLoading && selectedModule && !zohoFields[selectedModule] && setZohoFields(prev => ({ ...prev, [selectedModule]: ZohoFields?.fields }));
  }, [fieldLoading, selectedModule]);

  useEffect(() => {
    // @ts-ignore
    !modulesLoading && setZohoModules(ZohoModules?.modules);
  }, [modulesLoading]);

  useEffect(() => {
    if (initialMaps) {
      setMaps(transformObject(initialMaps));
      const modules = extractModuleNames(initialMaps);
      modules.forEach(async (module: string, index: number, array: any) => {
        (index === (array.length - 1)) && form.setFieldsValue({ rows: array.map(() => ({ name: index, key: index})) });
        // @ts-ignore
        const { fields } = await fetchData(module);
        setZohoFields(prev => ({ ...prev, [module]: fields }));
      });
    }
  }, [initialMaps]);

  useEffect(() => {
    disconnect?.success && window.close();
  }, [disconnect])

  const onFinish = () => postMappings(convertToApiModel(maps));

  const handleChange = (value: string, key: number, type: string, paths?: any) => {    
    setMaps(prev => {
      return ({
        ...prev,
        [key]: {
          ...prev[key],
          [type]: value,
        }
      })
    });
  };

  const handleDeleteRow = (key: number) => {
    setMaps(prev => {
      const newRows = { ...prev };
      delete newRows[key];
      return newRows;
    })
  };

  const checkIsRowActive = (key: number) => maps[key] && maps[key].module && maps[key].event;

  return (
    <div className="event-form-holder">
      <Form
        form={form}
        autoComplete="off"
        onFinish={onFinish}
        className="event-form"
        style={{ maxWidth: 600 }}
        name="dynamic_form_nest_item"
      >
        <Form.List name="rows">
          {(fields, { add, remove }) => (
            <>
              {fields.map(({ key, name, ...restField }) => {
                return (
                  <Space className="event-form-row-holder" key={key}>
                    <div className="form-row-modules">
                      <Form.Item
                        {...restField}
                        name={[name, 'module']}
                        initialValue={maps[key]?.module}
                        rules={[{ required: true, message: 'Required' }]}
                      >
                        <Select
                          allowClear
                          size="large"
                          bordered={false}
                          placeholder="Select Module"
                          onChange={value => {
                            // @ts-ignore
                            !zohoFields[value] && fetchData(value);
                            setSelectedModule(value);
                            handleChange(value, key, 'module');
                          }}
                        >
                          {zohoModules.map(({ label, name }, i) => (
                            <Select.Option key={`${i}${name}`} value={name}>
                              {label}
                            </Select.Option>
                          ))}
                        </Select>
                      </Form.Item>
                      <div className="connection-symbol" />
                      <div className="select-with-close-wrapper">
                        <Form.Item
                          {...restField}
                          name={[name, 'event']}
                          initialValue={maps[key]?.event}
                          rules={[{ required: true, message: 'Required' }]}
                        >
                          <Select
                            allowClear
                            size="large"
                            bordered={false}
                            placeholder="Select Event"
                            onChange={value => handleChange(value, key, 'event')}
                          >
                            {/*@ts-ignore*/}
                            {events.map(({ label, name }, i) => (
                              <Select.Option key={`${i}${name}`} value={name}>
                                {label}
                              </Select.Option>
                            ))}
                          </Select>
                        </Form.Item>
                        <Button
                          type="text"
                          size="large"
                          className="row-delete-button"
                          onClick={() => {
                            remove(name);
                            handleDeleteRow(key);
                          }}
                        >
                          <CloseIcon />
                        </Button>
                      </div>
                    </div>
                    {checkIsRowActive(key) && (
                      !fieldLoading ? (
                        <EventNestedForm
                          id={key}
                          maps={maps}
                          fieldName={name}
                          restField={restField}
                          handleChange={handleChange}
                          // @ts-ignore
                          zohoFieldList={zohoFields[maps[key].module] || []}
                          // @ts-ignore
                          eventFieldsList={events.find(({ name }: any) => name === maps[key].event)?.fields}
                        />
                      ) : (
                        <div className="fields-loader-holder">
                          <span className="fields-loader"></span>
                        </div>
                      )
                    )}
                  </Space>
                );
              })}
              <Form.Item className="add-event-holder">
                <Button size="large" type="dashed" onClick={() => add()} block>
                  <span className="add-icon-holder">
                    <PlusIcon />
                  </span>
                  <span>Add event</span>
                </Button>
              </Form.Item>
              <Form.Item className="save-button-holder">
                <Button
                  size="large"
                  type="primary"
                  htmlType="submit"
                  loading={postMappingsLoadingState}
                  disabled={postMappingsLoadingState}
                >
                  Save Changes
                </Button>
              </Form.Item>
              {/*<Button*/}
              {/*  size="large"*/}
              {/*  type="default"*/}
              {/*  className="disconnect-button"*/}
              {/*  onClick={() => setDisconnectModalState(true)}*/}
              {/*  // @ts-ignore*/}
              {/*  style={{ '--x-pos': postMappingsLoadingState ? '29%' : '25%' }}*/}
              {/*>*/}
              {/*  Disconnect*/}
              {/*</Button>*/}
            </>
          )}
        </Form.List>
      </Form>
      <Modal
        centered
        width={350}
        maskClosable={false}
        destroyOnClose={true}
        open={disconnectModalState}
        className="disconnect-modal"
        onCancel={() => setDisconnectModalState(false)}
        footer={
          <>
            <Button onClick={() => setDisconnectModalState(false)}>Cancel</Button>
            <Button type="primary" loading={disconnectLoading} onClick={() => disconnectRequest('')}>Disconnect</Button>
          </>
        }
      >
        <div className="disconnect-modal-body">
          <h3>Are you sure you want to disconnect?</h3>
          <p>Disconnecting will delete all your mappings and this page will be closed.</p>
        </div>
      </Modal>
    </div>
  );
}

export default EventForm;
